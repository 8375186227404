import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.4_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@15.2.4_@opentelemetry+api@1.9.0_react-dom@19.0.0_react@19.0.0__react@19.0.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["useMenus"] */ "/vercel/path0/personly-libs/personly-libs-providers/dist/providers/menus.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/card-button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/checkbox/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/delay/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/icon-select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/masonry/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/menu-links/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/menu-toggler-button/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/text-select/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/textarea/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/toast/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/toggle-description/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/toggle/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-libs/personly-libs-widgets/dist/widgets/upload/index.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/personly-web/personly-web-corporate/src/components/jumbotron/styles.module.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/personly-web/personly-web-corporate/src/components/picture/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/personly-web/personly-web-corporate/src/components/select-scheme/index.tsx");
